<template>
  <div>
    <div class="title">
      <div>综合营业统计</div>
      <div>
        <download-excel
          :data="json_data"
          :fields="json_fields"
          name="综合营业统计">
          <Button icon="md-download" type="text" class="md-download-btn" @click="exportReport">导出</Button>
        </download-excel>
      </div>
    </div>
    <div class="tab">
      <base-form ref="baseForm" :formList="formList" @search="search"></base-form>
    </div>
    <div class="table" ref="tableBox">
      <!-- <div class="Tabs">
        <Tabs v-model="tabsValue">
          <TabPane v-for="item in tabsList" :key="item.value" :label="item.label" :name="item.value"></TabPane>
      </Tabs>
      :page="page"
      </div> -->
      <IviewTable
        :height="tableHeight"
        :border="true"
        :columns="tableColumns"
        :data="tableData"
        :context="self"
        backStage
        show-summary
        :summary-method="handleSummary" 
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      ></IviewTable>
    </div>
    
  </div>
</template>

<script>
import { formatTime,getTime } from "@/utils";
import IviewTable from '_c/iview/IviewTable'
import baseForm from '@/components/baseForm'
import mixin from '@/mixins'
let toYuan = (h, params, props) => {
  return h(
    'span',
    {
      props: {},
    },
    (params.row[props]/100).toFixed(2)
  )
}
export default {
  name: '',
  components: {
    baseForm,
    IviewTable,
  },
  mixins: [mixin],
  data() {
    return {
      self: this,
      modalFormStatus: false,
      tableHeight:0,
      formValidate: {
        dateType:"按日",
        dataRange: [],
      },
      tabsValue: "0",
      tabsList:[{
        label:"全部订单",
        value:"0"
      },{
        label:"待接单",
        value:"1"
      },{
        label:"待制作",
        value:"2"
      },{
        label:"未结账",
        value:"3"
      },{
        label:"已结账",
        value:"4"
      },{
        label:"已撤单",
        value:"5"
      },{
        label:"已退单",
        value:"6"
      }],
      formList: [
        // {
        //   label: '统计周期',
        //   type: 'radio-button',
        //   model: 'dateType',
        //   options: [{
        //     label:"按日"
        //   },{
        //     label:"按月"
        //   },{
        //     label:"自定义周期"
        //   }]
        // },
        {
          label: '营业日期',
          type: 'datePicker-daterange',
          model: 'dataRange',
        }
      ],
      page: {
        current: 1,
        pageSize: 40,
        total: 0,
      },
      rowData: {},
      tableData: [],
      tableColumns: [
        {
          title: '日期',
          key: 'date',
        },
        {
          title: '订单笔数',
          key: 'orderTotal',
          summary: true
        },
        {
          title: '订单金额(元)',
          key: 'amount',
          summary: true,
          render: (h, params) => toYuan(h, params, 'amount')
        }
        // {
        //   title: '操作',
        //   key: 'action',
        //   fixed: 'right',
        //   width: 220,
        //   render: (h, params) => {
        //     return h('div', [
        //       h(
        //         'span',
        //         {
        //           style: { marginRight: '10px', color: '#66bc7a', cursor: 'pointer' },
        //           props: {},
        //           on: {
        //             click: () => {
        //               this.updateData(params.row)
        //               this.rowData = params.row
        //             },
        //           },
        //         },
        //         '编辑'
        //       ),
        //       h(
        //         'span',
        //         {
        //           style: { marginRight: '10px', color: '#bc7766', cursor: 'pointer' },
        //           on: {
        //             click: () => {
        //               this.updateSatus(params.row)
        //               this.rowData = params.row
        //             },
        //           },
        //         },
        //         params.row.enable ? '禁用' : '启用'
        //       ),
        //       h(
        //         'span',
        //         {
        //           style: { marginRight: '10px', color: 'red', cursor: 'pointer' },
        //           on: {
        //             click: () => {
        //               this.deleteUsers(params.row)
        //               this.rowData = params.row
        //             },
        //           },
        //         },
        //         '删除'
        //       ),
        //       h(
        //         'span',
        //         {
        //           style: { marginRight: '0px', color: '#66bc7a', cursor: 'pointer' },
        //           on: {
        //             click: () => {
        //               this.openUpdatePassword(params.row)
        //               this.rowData = params.row
        //             },
        //           },
        //         },
        //         '修改密码'
        //       ),
        //     ])
        //   },
        // },
      ],
      json_data:[],
      json_fields: {
        "日期":'date',
        "订单笔数":'orderTotal',
        "订单金额(元)":'amount'
      }
    }
  },
  mounted() {
    this.formValidate.dataRange = [getTime(),getTime(new Date(),[23,59,59,0])]
    this.$refs.baseForm.setData(this.formValidate)
    this.getElementHeight();
    // 监听页面rezise事件
    window.addEventListener("resize", this.getElementHeight);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.getElementHeight);
    });
  },
  methods: {
    exportReport(){
      if(this.json_data.length==0){
        this.$Message.warning('请先查询要导出的内容');
      }
    },
    getElementHeight() {
      const element = this.$refs.tableBox;
      const height = element.offsetHeight; // 或者使用其他的属性，如clientHeight、scrollHeight等
      // 其他逻辑处理
      this.tableHeight = height - 90
    },
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
          if (index === 0) {
              sums[key] = {
                key,
                value: '合计'
              };
              return;
          }
          if (!column.summary) {
              sums[key] = {
                  key,
                  value: ''
              };
              return;
          }
          const values = data.map(item => Number(item[key]));
          if (!values.every(value => isNaN(value)) && values.length>0) {
              const v = values.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                      return prev + curr;
                  } else {
                      return prev;
                  }
              }, 0);
              sums[key] = {
                key,
                value: key === 'amount' ? (v/100).toFixed(2) : v
              };
          } else {
              sums[key] = {
                  key,
                  value: key === 'amount' ? '0.00' : 0
              };
          }
      });
      return sums;
    },
    pageChange(page){
      this.page.current = page
      this.formValidate.page = page
      this.getOrderGroup(this.formValidate)
    },
    pageSizeChange(pageSize){
      this.page.current = 1
      this.page.pageSize = pageSize
      this.formValidate.page = 1
      this.formValidate.pageSize = pageSize
      this.getOrderGroup(this.formValidate)
    },
    search(data,close) {
      this.page.current = 1
      let params = {
        ...data,
        page: this.page.current,
        pageSize: this.page.pageSize,
      }
      if(close){
        this.page.total = 0
        this.tableData = []
        return
      }
      if(this.isAdmin && (!data || !data.orgId)){
        this.$Message.warning('请选择要查询的门店');
      }else if(!data || !data.dataRange || data.dataRange.length!==2 || !data.dataRange[0] || !data.dataRange[1]){
        this.$Message.warning('请选择要查询的日期');
      } else {
        params.start = formatTime(data.dataRange[0])[1]
        params.end = formatTime(data.dataRange[1])[1]
        this.formValidate = params
        this.getOrderGroup(params)
      }
      // if(data && data.dataRange && data.dataRange.length==2 && data.dataRange[0] && data.dataRange[1]){
      //   params.start = formatTime(data.dataRange[0])[1]
      //   params.end = formatTime(data.dataRange[1])[1]
      //   this.formValidate = params
      //   this.getOrderGroup(params)
      // } else {
      //   if(close){
      //     this.page.total = 0
      //     this.tableData = []
      //   }else{
      //     this.$Message.warning('请选择要查询的日期');
      //   }
      // }
    },
    getOrderGroup(params = {}) {
      this.$api.order.getOrderGroup(params).then((res) => {
        if(Object.keys(res).length === 0){
          this.$Message.warning('暂无数据');
        }
        let tableData = Object.keys(res).map((key) => {
          const _obj = res[key]
          return {
            date: key,
            orderTotal: _obj.checkedCount || 0,
            amount: _obj.checkedIncome || 0
          }
        })
        tableData = tableData.sort((a,b)=>{return a.date.replace(/-/g, '') - b.date.replace(/-/g, '')})
        this.tableData = tableData
        this.json_data = tableData.map(v=>{
          return {
            ...v,
            amount: (v.amount/100).toFixed(2)
          }
        })
        // this.page.total = res.count
      })
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 28px;
  font-size: 24px;
  .md-download-btn {
    font-size: 16px;
  }
}
.tab {
  background: #ffffff;
}
.table {
  background: #ffffff;
  padding: 0 30px;
  position: relative;
  height: calc(100vh - 155px);
  /deep/.i-page.fixed {
    position: absolute;
    bottom: -40px;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
// .btn-save {
//   width: 80px;
//   height: 30px;
//   background: #85c989;
//   box-shadow: 0px 2px 2px 0px #448247, 0px 0px 1px 0px #b4ffb9;
//   font-size: 14px;
//   font-weight: bold;
//   color: #ffffff;
//   text-shadow: 0px 1px 1px #163124, 0px 3px 5px #bcffca;
//   margin-right: 11px;
//   border: none;
// }
</style>
